import React from "react";
import Layout from "../components/Layout";
import PrivacyPolicy from "../components/PrivacyPolicy";

const seo = {
  title: "Privacy Policy",
  description: "See the Plastometrex privacy policy",
};

const PrivacyPolicyPage = () => {
  return (
    <Layout seo={seo} backgroundColorUnderneath="white">
      <PrivacyPolicy />
    </Layout>
  );
};

export default PrivacyPolicyPage;
